<template>
<div>
  <b-card
    title="基本信息"
  >
      <b-row>
        <b-col cols="4">
          <form ref="roleForm">
            <b-form-group
              label="角色名称"
              label-for="basicInput"
              :state="roleNameState"
              invalid-feedback="必须填写角色名称"
            >
              <b-form-input
                required
                :state="roleNameState"
                id="basicInput"
                v-model="role.name"
                placeholder="请输入角色名称"
              />
            </b-form-group>
            <b-form-group
              label="备注说明"
              label-for="basicInput"
            >
              <b-form-input
                id="basicInput"
                v-model="role.note"
                placeholder="请输入角色备注"
              />
            </b-form-group>            
          </form>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="功能权限设置"
    >
    <b-form-checkbox-group
      v-model="roleEnabledSelected"
      :options="roleEnabledOptions"
      class="mb-3"
      value-field="item"
      text-field="name"
      disabled-field="notEnabled"
    ></b-form-checkbox-group>
      <el-tree
          node-key="id"
          :data="permissionTree"
          ref="tree"
          show-checkbox
          :default-expanded-keys="roleHavePermissionIdList"
          :default-checked-keys="roleHavePermissionIdList"
          :props="permissionTreeProps"
          accordion>
        <template slot-scope="{ node }">
            <span>
              <span>{{ node.data.type==1?node.label+'(管理页面)':node.label+'(功能接口)' }}</span>
              <span
                      v-if="node.checked"
                      style="margin-left: 10px;"
                      class="el-icon-check"
              ></span>
            </span>
        </template>
      </el-tree>
    </b-card>
    <div>
      <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      v-if="$checkButtonPermission('202003001')"
      @click="save"
      >
      保存
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        @click="back"
      >
       取消
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BCard,BFormTextarea,BFormRadioGroup,BFormCheckboxGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import request from '@/api/request'

export default {
  components: {
    BCard,BFormRadioGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BFormCheckboxGroup
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      role:{
        id:"",
        name: "",
        note: "",
        webEnabled: true,
        appEnabled: true
      },
      roleNameState: null,
      permissionTree:[],
      roleHavePermissionIdList: [],
      permissionTreeProps: {
          children: 'children',
          label: 'title'
      },
      roleEnabledOptions: [
        { item: 'WEB', name: '访问WEB端' },
        { item: 'APP', name: '访问APP端' },        
      ],
      roleEnabledSelected:[],
      count: 1
    }
  },
  created() {
    this.getPermissionTree()
    console.log('this.$route.params',this.$route.params)
    if(this.$route.query && this.$route.query.id){
      this.getRoleInfo(this.$route.query.id)
    }
  },
  methods:{
    back(){
      this.$router.back()
    },
    getPermissionTree(){
      request.get('tob/user/bPermission/tree', {}).then(res => {
        if (res.data.success) {
          this.permissionTree = res.data.data
        }
      })
    },
    async getRoleInfo(id){
      await request.get('tob/user/bRole/info', {roleId: id}).then(res => {
        if (res.data.success) {
          console.log(res.data.data)
          const {id,name,note,webEnabled,appEnabled} = res.data.data
          this.role = {id,name,note,webEnabled,appEnabled}
          webEnabled ? this.roleEnabledSelected.push("WEB") : null
          appEnabled ? this.roleEnabledSelected.push("APP") : null
          this.roleHavePermissionIdList = res.data.data.permissionIdList
        }
      })
      let that = this
      setTimeout(function () {
          that.cancelTreeNodeChecked();
      },500)
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleNodeClick(data) {
      console.log(data);
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: 'region1' }, { name: 'region2' }]);
      }
      if (node.level > 3) return resolve([]);

      var hasChild;
      if (node.data.name === 'region1') {
        hasChild = true;
      } else if (node.data.name === 'region2') {
        hasChild = false;
      } else {
        hasChild = Math.random() > 0.5;
      }

      setTimeout(() => {
        var data;
        if (hasChild) {
          data = [{
            name: 'zone' + this.count++
          }, {
            name: 'zone' + this.count++
          }];
        } else {
          data = [];
        }

        resolve(data);
      }, 500);
    },
    /**
     * 保存角色
     */
    save(){
      console.log('this.role',this.role)
      const valid = this.$refs.roleForm.checkValidity()
      this.roleNameState = valid
      if(!valid)
        return
      this.roleEnabledSelected.indexOf('APP') >= 0 ? this.role.appEnabled = true : this.role.appEnabled = false
      this.roleEnabledSelected.indexOf('WEB') >= 0 ? this.role.webEnabled = true : this.role.webEnabled = false
      let checkedKeys = this.$refs.tree.getCheckedKeys()
      let halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys()
      checkedKeys = [...checkedKeys,...halfCheckedKeys]
      console.log('checkedKeys',checkedKeys)
      let role = JSON.parse(JSON.stringify(this.role))
      role.permissionIdList = checkedKeys


      request.post('tob/user/bRole/save', role).then(res => {
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
        }
        this.roleNameState = null
      })

    },
    /**
     * 取消勾选
     */
    cancelTreeNodeChecked(){
        let checkedKeys = this.$refs.tree.getCheckedKeys();
        // 获取当前选中的节点
        if (this.roleHavePermissionIdList.length > 0){
            if (checkedKeys.length > 0){
                let cancelKeys = checkedKeys.filter(v => !this.roleHavePermissionIdList.includes(v))
                for (let i=0;i < cancelKeys.length;i++){
                    this.$refs.tree.setChecked(cancelKeys[i],false,false);
                }
            }
        }

    }
  },
  watch:{
  }
}
</script>

<style lang="scss" scoped>
.list{
    .b-s-p{
        padding: 1rem;
    }
  padding: 0;
  list-style: none;
  .list-auth{
    .list-auth-cont{
      display: flex;
      justify-content: flex-start;
      border-bottom:1px solid #d8d6de;
    }
    .list-auth-cont .allCheck{
      width: 15%;
      padding-top: 10px;
    }
    .list-auth-cont .oneCheck{
        width: 85%;
        span{
            display: flex;
            justify-content: flex-start;
            flex-flow: wrap;
            div{
                width: 28%;
            }
        }
    }
  }
}
</style>